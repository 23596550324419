<template>
  <!-- <div class="print-action-holder">
    <button class="print-action-holder__btn-print" @click="printContent">
      Print
    </button>
  </div> -->
  <div id="invoice-body" class="invoice-body">
    <div v-show="show" id="invoice">
      <div :style="{'padding': '10px','background-color': '#ffffff',
         'direction': 'rtl', 'text-align': 'right' }">
        <div v-if="rel_logo" style="width: 100%; text-align: center;padding-bottom: 10px;padding-top: 15px;"><img :src="rel_logo" alt="" style="width: 80px;"></div>
        <h4 v-if="customer_type == 1 && is_company_have_tax" class="text-center font-weight-800">فاتورة ضريبية مبسطة SIMPLE TAX INVOICE</h4>
        <h4 v-else-if="customer_type == 2 && is_company_have_tax" class="text-center font-weight-800">فاتورة ضريبية TAX INVOICE</h4>
        <h4 v-else class="text-center font-weight-800">فاتورة INVOICE</h4>
        <h4 v-if="inContentTemp('invoice_code')" class="text-center font-weight-800" style="margin-top: 15px">
          رقم الفاتورة: {{ data.invoice_code }}
        </h4>
        <div style="text-align: center;">
          <vue-barcode id="vueBarcode" v-if="barcode" :value="barcode" tag="img" style="width: 150px;" :options="{ displayValue: true }"></vue-barcode>

        </div>
        <h3 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.company && data.company.business_name &&  inContentTemp('business_name_ar')">{{ data.company.business_name }}</h3>
        <h3 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.company && data.company.business_name_en &&  inContentTemp('business_name_en')">{{ data.company.business_name_en }}</h3>
        <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">
          <span v-if="inContentTemp('company_city_name')">{{ data.company ? (data.company.city_name ? ' - ' + data.company.city_name : '') : '' }}</span>
          <span v-if="inContentTemp('company_state')">{{ data.company ? (data.company.state ? ' - ' + data.company.state : '') : '' }}</span>
          <span v-if="inContentTemp('company_address_1')">{{ data.company ? (data.company.address_1 ? ' - ' + data.company.address_1 : '') : '' }}</span>
          <span v-if="inContentTemp('company_postal_code')">{{ data.company ? (data.company.postal_code ? ' - ' + data.company.postal_code : '') : '' }}</span>
        </p>

        <div v-if="data.pos_store && is_show_contact && (mobileFullData(data.pos_store.mobile) || mobileFullData(data.pos_store.mobile2))" style="width: 100%;display: block;background-color: #cccccc;">
          <h5 style="margin-bottom: 0;text-align: center;padding-top: 5px;padding-bottom: 5px;">
            <span>التواصل: </span>
            <span v-if="mobileFullData(data.pos_store.mobile)" style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile) }}</span>
            <template v-if="mobileFullData(data.pos_store.mobile2)">
              <span style="direction: ltr;display: inline-block;margin-right: 5px;margin-left: 5px;"> - </span>
              <span style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile2) }}</span>
            </template>
          </h5>
        </div>
        <p class="text-center" v-if="data.company && data.company.business_record && inContentTemp('business_record')" style="margin-bottom: 5px;margin-top: 5px;">
          <span><b> السجل التجاري: </b></span>
          <span>{{ data.company.business_record }}</span>
        </p>
        <p class="text-center" v-if="data.company && data.company.tax_no && inContentTemp('tax_no')" style="margin-bottom: 5px;margin-top: 5px;">
          <span><b> الرقم الضريبي: </b></span>
          <span>{{ data.company.tax_no }}</span>
        </p>
        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>

        <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 5px 10px;">
          <p style="margin-bottom: 0;margin-top: 0;" v-if="inContentTemp('invoice_date')"><span><b> تاريخ الفاتورة: </b> <span> {{ data.create_date_time }} </span></span></p>
          <p style="margin-bottom: 0;margin-top: 10px;">
          <span v-if="inContentTemp('employee_user_name')">
            <b> أمين الصندوق: </b>
            <span v-if="data.pos_session && data.pos_session.user_name"> {{ data.pos_session.user_name }} </span>
          </span>
          </p>
        </div>

        <div v-if="is_show_customer_details" style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;">
          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer && data.customer.name">
            <span v-if="inContentTemp('fullname')" style="width: 100%;"><span><b> العميل: </b> </span><span> {{ data.customer.name }} </span></span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile)" style="margin-right: 10px;margin-left: 10px;"> | </span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile)" style="direction: ltr;display: inline-block;width: 100%;"> {{ mobileFullData(data.customer.mobile) }} </span>
          </p>
          <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')">
            <span><b> الرقم الضريبي: </b></span>
            <span>{{ data.customer.tax_register }}</span>
          </p>
          <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.commercial_register && inContentTemp('commercial_register')">
            <span><b> السجل التجاري: </b></span>
            <span>{{ data.customer.commercial_register }}</span>
          </p>
          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer">
            <span v-if="inContentTemp('customer_code')" style="width: 100%;"><span><b> رقم العميل: </b> </span><span v-if="data.customer.customer_code">{{ data.customer.customer_code }}#</span></span>
            <span v-if="inContentTemp('customer_debit') || inContentTemp('customer_credit')" style="margin-right: 10px;margin-left: 10px;">|</span>
            <span style="width: 100%;" v-if="data.customer_balance && data.customer_balance.credit && inContentTemp('customer_credit')">
              <span><b> الرصيد: </b> </span><span>{{ data.customer_balance.credit }}</span>
            </span>
            <span v-else-if="data.customer_balance && data.customer_balance.debit && inContentTemp('customer_debit')">
              <span style="width: 100%;"><b> الرصيد المستحق: </b> </span><span>{{ data.customer_balance.debit }}</span>
            </span>
          </p>
          <p style="margin-bottom: 0;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')"><span><b> الرقم الضريبي: </b> </span><span> {{ data.customer.tax_register }} </span></p>
        </div>

        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>


        <div style="margin-top: 15px;">
          <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;" v-for="(row, index) in items_list" :key="index">
            <div style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;">
                <span v-if="index == 0" style="display: block;"><b>المنتج</b></span>
                <span style="display: block;">{{ row.item ? row.item.name : '' }}</span>
              </p>
              <p style="margin: 0;">
                <span v-if="index == 0" style="display: block;"><b>العدد</b></span>
                <span style="display: block;">{{ row.qty }}</span>
              </p>
            </div>
            <div style="margin-bottom: 0;margin-top:10px;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;display: contents;">
                <span><b>السعر</b></span>
                <span> {{ row.unit_price }}</span>
              </p>
              <p style="margin: 0;display: contents;">
                <span><b>VAT</b></span>
                <span> {{ row.amount_tax ? row.amount_tax : '0' }}</span>
              </p>
              <p style="margin: 0;display: contents;">
                <span><b>الخصم</b></span>
                <span> {{ row.discount_calc ? row.discount_calc : '0' }}</span>
              </p>
              <p style="margin: 0;display: contents;">
                <span><b>الإجمالي</b></span>
                <span> {{ row.subtotal_after_tax ? row.subtotal_after_tax : '0' }}</span>
              </p>
            </div>
            <div style="margin-bottom: 0;margin-top:10px;display: flex;width: 100%;">
              <span v-for="(damage, damage_index) in row.damages_list" :key="'damage_index'+damage_index"> {{ damage.name }} / </span>
              <span v-for="(stain, stain_index) in row.stains_list" :key="'stain_index'+stain_index"> {{ stain.name }} / </span>
              <span v-for="(bubbles, bubbles_index) in row.bubbles_list" :key="'bubbles_index'+bubbles_index"> {{ bubbles.name }} / </span>

            </div>
            <p><span style="border-bottom: 2px dotted #000;width: 70%;height: 0;display: block;margin: 0 auto;"></span></p>
          </div>
        </div>


        <div style="margin-top: 15px;">
          <div style="min-width: 264px;padding-left: 5px;">
            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_subtotal')">
              <div><h5 class="mb-0">الاجمالي قبل الضريبة \ Sub Total</h5></div>
              <div><span><b>{{ data.invoice_total_before_taxes ? data.invoice_total_before_taxes : '0' }}</b></span> <span> {{ data.currency_code }} </span></div>
            </div>
            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_discount')">
              <div><h5 class="mb-0">الخصم \ Discount</h5></div>
              <div><span class="sum-sum"><b> {{ data.invoice_total_discount ? data.invoice_total_discount : '0' }} </b></span> <span>{{ data.currency_code }}</span></div>
            </div>

            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_taxes')">
              <h5 class="mb-0">
              <span>
                ضريبة القيمة المضافة \ VAT 15%
              </span>
              </h5>
              <div><span class="sum-sum"><b> {{ data.invoice_total_taxes ? data.invoice_total_taxes : '0' }} </b></span> <span> {{ data.currency_code }} </span></div>
            </div>

            <div class="d-flex justify-content-between special-border mt-1" v-if="inContentTemp('invoice_total_after_taxes')">
              <h5 class="mb-0">
              <span>
                الاجمالي شامل الضريبة \ Total
              </span>
              </h5>
              <div><span><b>{{ data.invoice_total ? data.invoice_total : '0' }}</b></span> <span> {{ data.currency_code }} </span></div>
            </div>
            <div v-if="is_payment_details" class="d-flex justify-content-between special-border mt-1" style="align-items: start;">
              <div><h5 class="mb-0">طرق الدفع \ Payment methods</h5></div>
              <div>
                <span style="margin-bottom: 10px;display: block;" v-for="(_payment , _index) in data.payment_amount_details" :key="'_payment'+_index">
                  <template v-if="_payment.payment_method_name">
                    <span> {{ _payment.payment_method_name }} </span> <span> {{ _payment.amount }} </span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
          <div class="text-center " style="margin: 5px auto;width: 190px;height: 203px;margin-top: 15px;">
            <div v-show="data.data_qr_code" style="padding: 5px;width: 190px;height: 203px;">
              <qr-code :text="data.data_qr_code ? data.data_qr_code : '1'" :size="size" :error-level="'L'"></qr-code>
            </div>
          </div>

          <div v-if="data.pos_policy && is_show_terms_and_conditions">
            <p v-if="is_break_before_footer"><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
            <h4 class="text-center">الشروط والأحكام</h4>
            <div class="mb-0 ql-editor" style="float: right;text-align: right;width: 100%;height: auto;padding: 0;"  v-html="data.pos_policy"></div>
          </div>
<!--          <div v-if="data.usage_policy && is_show_terms_and_conditions">-->
<!--            <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>-->
<!--            <p>{{ data.usage_policy }}</p>-->
<!--          </div>-->


        </div>

      </div>

    </div>
  </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueBarcode from '@chenfengyuan/vue-barcode';

export default {
  name: "point-laundry-sale-invoice",
  components: {
    'qr-code': VueQRCodeComponent,
    VueBarcode
  },
  data() {
    return {
      fetched: false,
      lang: this.$i18n.locale,
      mainRoute: 'sales/sales_laundry/print',
      data: {},
      items_list: [],
      size: 175,
      calc_total: 0,
      sales_invoice_id: this.$route.params.sales_invoice_id,
      type: this.$route.params.type ? this.$route.params.type : null,
      printWindow: '',
      show: false,

      templateMainRoute: 'settings/print_setting/type',
      template_for: 'PosLaundry',
      template: null,
      content_temp: [],
      is_break_before_footer: false,
      is_payment_details: false,
      is_empty_row: false,
      is_show_contact: false,
      is_show_customer_details: false,
      is_show_terms_and_conditions: false,
      print_size: '80',
      customer_type: null,
      is_company_have_tax: false,
    }
  },
  computed: {
    barcode: function () {
      let val1 = Math.floor(100000 + Math.random() * 999999);
      let val2 = Math.floor(10000 + Math.random() * 99999);
      return val1 + "" + val2;
    },
    rel_logo: function () {
      if (this.data && this.data.company && this.data.company.logo_url) {
        return this.data.company.logo_url;
      } else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }

    },
  },
  methods: {
    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.sales_invoice_id}`).then((response) => {
        this.data = response.data.data;
        this.items_list = response.data.data.items_list;
        this.is_company_have_tax = response.data.data.is_company_have_tax;
        if (response.data.data && response.data.data.customer) {
          this.customer_type = response.data.data.customer.customer_type;
        }

        this.show = true;

      });
    },

    printData(){
      this.printContent();
    },
    async printContent() {

      if (document.getElementById('invoice-body')) {
        const content = document.getElementById('invoice-body').innerHTML;

        const printWindow = window.open('', '_self');

        printWindow.document.write('<html><head><title>Print</title>');

        printWindow.document.write('<style>');
        printWindow.document.write(`
              @media print {
                @page {
                  size: auto;
                  margin: 2mm;
                }
              }
              .store-logo{
                position: absolute;
                top: 0;
                right: 0;
                height: 99px;
                margin: 13px;
              }
              #invoice {
                font-family: "NotoSansArabic";
                width: ${this.print_size}mm;
                position: relative;
              }
              h5{
                margin:0;
              }
              html, body {
                    margin: 0.39mm 0.27mm;
                    background: #EEF0F8;
              }

              table, thead, tr, th, tbody, td {

                font-size: 14px;
                background-color: transparent !important;
              }

              table{
                border: 1px solid #000000;
                border-radius: 6px;
                border-color: #000000 !important;
                width: 100%;
              }

              .text-center {
                text-align: center !important;
              }

              th, p, h5 {

                font-size: 14px !important;

              }

              .special-border {
                padding: 5px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
              }
              .table td {
                border-left: 1px solid #000000 !important;
                border-top: 0;
              }
              .table thead th {
                border-left: 1px solid #000000;
                border-bottom: 1px solid #000000;
              }
              .table thead tr {
                border: 0 !important;
              }
              .table thead th:last-child {
                border-left: 0 !important;
              }
              .table td:last-child {
                border-left: 0 !important;
              }
              .table th, .table td {
                padding: 5px !important;
              }

          `);
        printWindow.document.write('</style>');

        printWindow.document.write('</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');


        setTimeout(() => {
          printWindow.print();
          printWindow.close();
          this.printEleShow = false;
        }, 300);
      }
      // window.print();
      // window.close();
      // that.printWindow = window.open('', '_blank');
      // that.printWindow.document.write('<html><head><title>Print</title></head><body>');
      // that.printWindow.document.write(content);
      //
      // // Extract and append CSS styles to the new window
      // const stylesheets = Array.from(document.styleSheets);
      // stylesheets.forEach((stylesheet) => {
      //   if (stylesheet.href) {
      //     const linkTag = that.printWindow.document.createElement('link');
      //     linkTag.rel = 'stylesheet';
      //     linkTag.href = stylesheet.href;
      //     that.printWindow.document.head.appendChild(linkTag);
      //   } else if (stylesheet.rules) {
      //     const styleTag = that.printWindow.document.createElement('style');
      //     styleTag.type = 'text/css';
      //     styleTag.innerHTML = Array.from(stylesheet.rules).map((rule) => rule.cssText).join('\n');
      //     that.printWindow.document.head.appendChild(styleTag);
      //   }
      // });
      //
      // await that.printWindow.document.write('</body></html>');

      // that.printWindow.onafterprint = function () {
      //   that.printWindow.close();
      // };

      // setTimeout(() => {
      //   printWindow.print();
      // }, 100);
    },

    async printSetting() {
      await ApiService.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
        this.is_show_contact = response.data.data.is_show_contact ? response.data.data.is_show_contact : false;
        this.print_size = response.data.data.print_size ? response.data.data.print_size : '80';
        this.is_show_customer_details = response.data.data.is_show_customer_details ? response.data.data.is_show_customer_details : false;
        this.is_show_terms_and_conditions = response.data.data.is_show_terms_and_conditions ? response.data.data.is_show_terms_and_conditions : false;


      });
    },
    inContentTemp(content) {
      if (this.content_temp.includes(content))
        return true;
      else
        return false;
    },

    mobileFullData(mobile){
      if (mobile){
        if (mobile.length > 5){
          return mobile;
        }
      }
      return null;
    },
  },
  mounted() {
    let promise = this.getData();
    let promise1 = this.printSetting();
    Promise.all([promise, promise1]).then(() => {
      this.printData();
    });
  }
}
</script>

<style>
@media print {
  @page {
    size: auto;
    margin: 2mm;
  }
}

.store-logo {
  position: absolute;
  top: 0;
  right: 0;
  height: 99px;
  margin: 13px;
}

#invoice {
  font-family: "NotoSansArabic";
  width: 76mm;
  position: relative;
}

h5 {
  margin: 0;
}

html, body {
  margin: 0.39mm 0.27mm;
  background: #EEF0F8;
}

table, thead, tr, th, tbody, td {

  font-size: 14px;
  background-color: transparent !important;
}

table {
  border: 1px solid #000000;
  border-radius: 6px;
  border-color: #000000 !important;
  width: 100%;
}

.text-center {
  text-align: center !important;
}

th, p, h5 {

  font-size: 14px !important;

}

.special-border {
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.table td {
  border-left: 1px solid #000000 !important;
  border-top: 0;
}

.table thead th {
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.table thead tr {
  border: 0 !important;
}

.table thead th:last-child {
  border-left: 0 !important;
}

.table td:last-child {
  border-left: 0 !important;
}

.table th, .table td {
  padding: 5px !important;
}

</style>